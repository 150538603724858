// Dashboard pages
import Dashboard from '../components/Layout/Dashboard.vue'
const Overview = () => import(/* webpackChunkName: "tables" */ 'src/components/Views/Overview.vue');
const Quotes = () => import(/* webpackChunkName: "tables" */ 'src/components/Views/Quotes.vue');
const Orders = () => import(/* webpackChunkName: "tables" */ 'src/components/Views/Orders.vue');
const Reports = () => import(/* webpackChunkName: "tables" */ 'src/components/Views/Reports.vue');

// Adminstration pages
const AdminProviders = () => import("src/components/Views/Admin/Providers.vue");
const AdminCustomers = () => import("src/components/Views/Admin/Customers.vue");
const AdminAssets = () => import("src/components/Views/Admin/Assets.vue");

// GeneralViews
import NotFound from '../components/Views/NotFoundPage.vue'

const routes = [
  {
    path: '/',
    component: Dashboard,
    redirect: '/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'quotes',
        name: 'Quotes',
        component: Quotes
      },
      {
        path: 'orders',
        name: 'Orders',
        component: Orders
      },
      {
        path: 'reports',
        name: 'Reports',
        component: Reports
      },
    ]
  },
  {
    path: '/admin',
    component: Dashboard,
    redirect: '/admin/providers',
    children: [
      {
        path: 'providers',
        name: 'Quotes',
        component: AdminProviders
      },
      {
        path: 'customers',
        name: 'Customers',
        component: AdminCustomers
      },
      {
        path: 'assets',
        name: 'Assets',
        component: AdminAssets
      },
    ]
  },
  {path: '*', component: NotFound}
];

export default routes
