import Vue from 'vue';
import Vuex from 'vuex';

import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import reset from "./modules/reset"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alerts,
    profile,
    users,
    reset
  }
});
