export default [
  {
    name: 'Dashboard',
    icon: 'nc-icon nc-bank',
    path: '/'
  },
  {
    name: 'Quotes',
    icon: 'nc-icon nc-book-bookmark',
    path: '/quotes'
  },
  {
    name: 'Orders',
    icon: 'nc-icon nc-book-bookmark',
    path: '/orders'
  },
  {
    name: 'Reports',
    icon: 'nc-icon nc-layout-11',
    path: '/reports'
  },
  {
    name: 'Adminstration',
    icon: 'nc-icon nc-ruler-pencil',
    children: [{
      name: 'Providers',
      path: '/admin/providers'
    },
    {
      name: 'Customers',
      path: '/admin/customers'
    },
    {
      name: 'Assets',
      path: '/admin/assets'
    }
    ]
  }
]
