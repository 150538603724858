var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('side-bar',{attrs:{"active-color":_vm.activeColor,"background-color":_vm.backgroundColor,"type":"sidebar"},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'nc-icon nc-bank',
          path: '/',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Quotes',
          icon: 'nc-icon nc-sound-wave',
          path: '/quotes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Orders',
          icon: 'nc-icon nc-money-coins',
          path: '/orders',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Reports',
          icon: 'nc-icon nc-chart-pie-36',
          path: '/reports',
        }}}),_c('sidebar-item',{staticClass:"example",attrs:{"closed":"","link":{ name: 'Administration', icon: 'nc-icon nc-settings' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Providers',
            path: '/admin/providers',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Customers',
            path: '/admin/customers',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Assets',
            path: '/admin/assets',
          }}})],1)]}}])}),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }