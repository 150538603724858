<template>
  <div class="wrapper">
    <side-bar
      :active-color="activeColor"
      :background-color="backgroundColor"
      type="sidebar"
    >
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'nc-icon nc-bank',
            path: '/',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Quotes',
            icon: 'nc-icon nc-sound-wave',
            path: '/quotes',
          }"
        >
        </sidebar-item>
        
        <sidebar-item
          :link="{
            name: 'Orders',
            icon: 'nc-icon nc-money-coins',
            path: '/orders',
          }"
        >
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Reports',
            icon: 'nc-icon nc-chart-pie-36',
            path: '/reports',
          }"
        >
        </sidebar-item>
        <sidebar-item
          closed
          class="example"
          :link="{ name: 'Administration', icon: 'nc-icon nc-settings' }"
        >
          <sidebar-item
            :link="{
              name: 'Providers',
              path: '/admin/providers',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Customers',
              path: '/admin/customers',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Assets',
              path: '/admin/assets',
            }"
          />
        </sidebar-item>

      </template>
    </side-bar>


    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import SidebarItem from "../UIComponents/SidebarPlugin/SidebarItem.vue";
import SidebarShare from "./SidebarSharePlugin";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    SidebarItem,
    SidebarShare,
  },
  data() {
    return {
      backgroundColor: "black",
      activeColor: "success",
    };
  },

  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
