<template>
  <footer class="footer">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="https://xbullion.io" class="nav-link" target="_blank" rel="noopener">ABOUT US</a>
          </li>

      </ul>
      </nav>
       <div class="copyright pull-right">
            <i class="fa fa-copyright" style="font-weight: 200;"></i> {{ year }} xBullion
          </div>
    </div>
  </footer>
</template>
<script>
  export default {
    data() {
      return {
        year: new Date().getFullYear()
      };
    }
  }
</script>
<style>

</style>
