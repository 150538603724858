/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:09c6e435-3b9a-498c-b075-9ae02a3fbaa2",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_FVfLeSzqu",
    "aws_user_pools_web_client_id": "63erqa0fdmive3mhtjv4rpjmkm",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pkciovllu5f3hacu5yd4rj32h4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-scumbq76grachbduu4y47vnsia",
    "aws_cloud_logic_custom": [
        {
            "name": "xbapiquote",
            "endpoint": "https://uitget0rcd.execute-api.ap-southeast-1.amazonaws.com/staging",
            "region": "ap-southeast-1"
        }
    ]
};


export default awsmobile;
